import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import {
    OrganizationType,
    User,
    UserStore
} from '@quipex/shared/data';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[hasRole]',
    standalone: true
})
export class HasRoleDirective {
    private currentUser!: User;
    private roles!: string[];

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private userStore: UserStore
    ) { }

    @Input()
    set hasRole(val: string[]) {
        this.userStore.select("me")
            .subscribe(user => {
                if (user.role != null) {
                    this.roles = val;
                    this.currentUser = user;
                    this.updateView();
                }

            });
    }

    private updateView(): void {
        this.viewContainer?.clear();
        if (this.checkRole()) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }

    private checkRole() {
        let checkRoleResult = false;
        if (this.currentUser && this.currentUser.role) {
            //If the user is Quipex Admin, he/she has access with no limitation
            if (this.currentUser.orgCategory == OrganizationType.ADMIN) { // user belong to admin org
                return true;
            }
            const currentUserRole = this.currentUser.role.toLowerCase();
            this.roles.forEach((role) => {
                //The terms starting with ! are expected to not be included in the user role.  
                if (role[0] == '!') {
                    if (!currentUserRole.includes(role.toLowerCase().substring(1))) {
                        checkRoleResult = true;
                        return;
                    }
                }
                //The terms starting with *, as a wildcard, and expected to be part of the user role.
                else if (role[0] == '*') {
                    if (currentUserRole.includes(role.toLowerCase().substring(1))) {
                        checkRoleResult = true;
                        return;
                    }
                }
                //The other terms are expected to be exactly equal to the user role.
                else {
                    if (currentUserRole == role.toLowerCase()) {
                        checkRoleResult = true;
                        return;
                    }
                }
            });
        }
        this.roles = [];
        return checkRoleResult;
    }
}
