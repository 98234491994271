import { BuildingStatus } from "./enums.model";
export class AddNewBuilding {
    currentUserAdId!: string;
    buildingDTO!: BuildingDTO;
    constructor() {
        this.buildingDTO = new BuildingDTO();
        this.buildingDTO.developerDirectors = [];
        this.buildingDTO.keyDocuments = [];
    }
}

export class UpdateBuildingDTO {
    constructor() {
        this.developerDirectors = [];
    }
    buildingId = '';
    about = '';
    title = '';
    classId: Array<string> = [''];
    siteArea!: number;
    floorArea!: number;
    riseInStoreys!: number;
    effectiveHeight!: number;
    maxOccupants!: number;
    noOfSoleOccupancyUnits!: number;

    developerName = '';
    buildingSurveyorName = '';
    buildingSurveyorRegistrationNumber = '';
    developerDirectors: Director[] = [];
    occupancyCertificateDate?: Date
}

export class BuildingDTO {
    buildingId?: string;
    buildingName = '';
    about = '';
    title = '';
    postcode = '';
    cityName = '';
    streetName = '';
    streetNumber = '';
    stateName = '';
    state!: number;
    countryName = '';
    address!: string;
    propertyOwner!: any;
    builder!: any;
    buildingStatusId!: BuildingStatus;
    classId: Array<string> = [''];
    latitude = 0;
    longitude = 0;
    startDate = '';

    siteArea!: number;
    floorArea!: number;
    riseInStoreys!: number;
    effectiveHeight!: number;
    maxOccupants!: number;
    noOfSoleOccupancyUnits!: number;
    classes = '';

    developerName = '';
    buildingSurveyorName = '';
    buildingSurveyorRegistrationNumber = '';
    developerDirectors: Director[] = [];
    keyDocuments: KeyDocument[] = [];
    developerDirectorNames = '';
    occupancyCertificateDate?: Date;
    addressId?: string;
}

export interface IOCDetailsExtraction {
    title?: string;
    buildingClasses?: Array<string>;
    riseInStoreys?: number;
    effectiveHeight?: number;
    ocIssueDate?: Date;
    about?: Array<string>;
    buildingSurveyor?: string;
    buildingSurveyorRegistration?: string;
    address?: string;
    maxOccupants?: string;
}
export class Director {
    name!: string;
}

export class KeyDocument {
    documentId!: string;
    documentName!: string;
    keyDocumentType!: number;
}

export class Contact {
    id!: string | null;
    contactName!: string;
    companyName!: string;
    email!: string;
    phone!: string;
    contactType!: null | number;
    constructor() {
        this.id = null;
        this.contactName = '';
        this.companyName = '';
        this.email = '';
        this.phone = '';
        this.contactType = null;
    }
}

export class Building {
    buildingName!: string;
    title!: string;
    postcode!: number;
    city!: number;
    state!: number;
    address!: string;
    propertyOwner!: string;
    builder!: string;
    buildingStatusId!: BuildingStatus;
    classId!: Array<string>;
    latitude!: number;
    longitude!: number;
    startDate!: string;
}
export class imgModel {
    id!: string;
    fileBase64String!: string;
    fileExtension!: string;
}

export class ClassModel {
    classGuid!: string;
    constructionType!: string;
    description!: string;
    id!: number;
    name!: string;
    checked!: boolean;
}
