import { Injectable } from '@angular/core';
import { catchError, map, Observable, Subject } from 'rxjs';
import { ApiServiceBase } from '../api-service-base';
import {
    BuildingTaskCriticalEnum, IAddTaskPostModel, ICompleteTaskPostModel, IGetTaskListResponse, IGetTaskResponse,
    ITaskAssignedBuilding, ITaskAssignedOrganisation, ITaskAssignedUser, IUpdateAssignedOrg, IUpdateAssignedUser,
    IUpdateDefectPostModel, IUpdateTaskPostModel
} from '../models/typings/tasks.interface';
import { IBulkCompleteTasksModel } from '../models/typings/bulk-complete-tasks.interface';
import { IDefect } from '../models/typings/esm/esm-schedule.interface';

@Injectable({
    providedIn: 'root',
})
export class TasksApiService extends ApiServiceBase {
    buildingIdChange$: Subject<number> = new Subject<number>();

    private _apiBase = `${this.appConfig.apiBase}/api/tasks`;

    setBuildingId(id: number): void {
        this.buildingIdChange$.next(id);
    }

    getTasks(payload: any): Observable<IGetTaskListResponse> {
        const url = `${this._apiBase}/list`;
        return this.http.post<any>(url, payload)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    getTaskById(id: string): Observable<IGetTaskResponse> {
        const url = `${this._apiBase}/${id}`;
        return this.http.get<any>(url)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    getAssignedBuildings(): Observable<ITaskAssignedBuilding[]> {
        const url = `${this._apiBase}/assigned-buildings`;
        return this.http.get<any>(url)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    getAssignedOrganizations(): Observable<ITaskAssignedOrganisation[]> {
        const url = `${this._apiBase}/assigned-organizations`;
        return this.http.get<any>(url)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    getAssignedUsers(): Observable<ITaskAssignedUser[]> {
        const url = `${this._apiBase}/assigned-users`;
        return this.http.get<any>(url)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    updateAssignedOrg(model: IUpdateAssignedOrg): Observable<boolean> {
        const url = `${this._apiBase}/assign-org`;
        return this.http.put<boolean>(url, model)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    updateAssignedUser(model: IUpdateAssignedUser): Observable<boolean> {
        const url = `${this._apiBase}/assign-user`;
        return this.http.put<boolean>(url, model)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    addTask(model: IAddTaskPostModel): Observable<any> {
        const url = `${this._apiBase}`;
        return this.http.post<boolean>(url, model)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    updateDefect(model: IUpdateDefectPostModel): Observable<any> {
        const url = `${this._apiBase}/defect`;

        const formData = new FormData();
        formData.append('taskId', model.taskId.toString() ?? '');
        formData.append('status', model.status.toString() ?? '');
        formData.append('completionDate', model.completionDate?.toISOString() ?? '');//new Date().toLocaleTimeString() ?? '');
        formData.append('assignedOrgId', model.assignedOrgId?.toString() ?? '');
        formData.append('assignedUserId', model.assignedUserId?.toString() ?? '');

        this.appendDocuments(formData, model);

        model.addedNotes?.forEach((note, index) => {
            formData.append(`addedNotes[${index}]`, note);
        });

        return this.http.put<boolean>(url, formData)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    updateTask(model: IUpdateTaskPostModel): Observable<any> {
        const url = `${this._apiBase}`;

        const formData = new FormData();
        formData.append('taskId', model.taskId.toString() ?? '');
        formData.append('status', model.status.toString() ?? '');
        formData.append('assignedOrgId', model.assignedOrgId?.toString() ?? '');
        formData.append('assignedUserId', model.assignedUserId?.toString() ?? '');

        model.addedNotes?.forEach((note, index) => {
            formData.append(`addedNotes[${index}]`, note);
        });

        return this.http.put<boolean>(url, formData)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }


    completeTask(model: ICompleteTaskPostModel): Observable<any> {
        const url = `${this._apiBase}/complete`;

        const formData = new FormData();
        formData.append('taskId', model.taskId.toString() ?? '');
        formData.append('status', model.status.toString() ?? '');
        formData.append('completionDate', model.completionDate?.toISOString() ?? '');
        formData.append('assignedOrgId', model.assignedOrgId?.toString() ?? '');
        formData.append('assignedUserId', model.assignedUserId?.toString() ?? '');

        model.addedNotes?.forEach((note, index) => {
            formData.append(`addedNotes[${index}]`, note);
        });

        this.appendDocuments(formData, model);

        model.addedDefectDTOs?.forEach((defect: IDefect, index: number) => {
            const critical = defect.isCritical
                ? BuildingTaskCriticalEnum.Critical
                : BuildingTaskCriticalEnum.NotCritical;

            formData.append(`addedDefectDTOs[${index}].description`, defect.description);
            formData.append(`addedDefectDTOs[${index}].isCritical`, critical.toString());
        });

        return this.http.put<boolean>(url, formData)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    bulkCompleteTasks(model: IBulkCompleteTasksModel): Observable<any> {
        const url = `${this._apiBase}/bulk-complete`;

        const formData = new FormData();
        formData.append('Evidence', model.Evidence);
        formData.append('CompletionDate', model.CompletionDate.toString());

        model.TaskCompletions.forEach((taskCompletion, index) => {
            formData.append(`TaskCompletions[${index}].TaskId`, taskCompletion.TaskId);
            taskCompletion.AddedDefectDTOs.forEach((defect, defectIndex) => {
                formData.append(`TaskCompletions[${index}].AddedDefectDTOs[${defectIndex}].Description`, defect.Description);
                formData.append(`TaskCompletions[${index}].AddedDefectDTOs[${defectIndex}].IsCritical`, defect.IsCritical.toString());
                formData.append(`TaskCompletions[${index}].AddedDefectDTOs[${defectIndex}].IsResolved`, defect.IsResolved.toString());
            });
        });

        return this.http.put<any>(url, formData).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    private appendDocuments(formData: FormData, model: IUpdateDefectPostModel | ICompleteTaskPostModel): void {
        if (model.documents?.length) {
            model.documents.forEach((file) => {
                formData.append('evidences', file);
            });
        }
    }
}
