
export enum DocumentCategoryEnum {
    Permits = 1,
    EvacuationPlan = 2,
    UserManuals = 3,
    FloorPlans = 4,
    Drawings = 5,
    Firesafety = 6,
    FittingsAndFinishes = 7,
    BuildingMaintenance = 8,
    Other = 9
}

export enum DocumentCategoryNamesEnum {
    Permits = 'Permits',
    EvacuationPlan = 'Evacuation Plan',
    UserManuals = 'User Manuals',
    FloorPlans = 'Floor Plans',
    Drawings = 'Drawings',
    Firesafety = 'Fire Safety',
    FittingsAndFinishes = 'Fittings & Finishes',
    BuildingMaintenance = 'Building Maintenance',
    Other = 'Other'
}

export const documentCategoryOptions: { id: DocumentCategoryEnum, name: DocumentCategoryNamesEnum }[] = [
    { id: DocumentCategoryEnum.Permits, name: DocumentCategoryNamesEnum.Permits },
    { id: DocumentCategoryEnum.EvacuationPlan, name: DocumentCategoryNamesEnum.EvacuationPlan },
    { id: DocumentCategoryEnum.UserManuals, name: DocumentCategoryNamesEnum.UserManuals },
    { id: DocumentCategoryEnum.FloorPlans, name: DocumentCategoryNamesEnum.FloorPlans },
    { id: DocumentCategoryEnum.Drawings, name: DocumentCategoryNamesEnum.Drawings },
    { id: DocumentCategoryEnum.Firesafety, name: DocumentCategoryNamesEnum.Firesafety },
    { id: DocumentCategoryEnum.FittingsAndFinishes, name: DocumentCategoryNamesEnum.FittingsAndFinishes },
    { id: DocumentCategoryEnum.BuildingMaintenance, name: DocumentCategoryNamesEnum.BuildingMaintenance },
    { id: DocumentCategoryEnum.Other, name: DocumentCategoryNamesEnum.Other }
];


export enum DocumentTypeEnum {
    PlanningPermit = 1,
    BuildingPermit = 2,
    OccupancyPermit = 3,
    EvacuationPlans = 4,
    UserManual = 5,
    FloorPlans = 6,
    Drawings = 7,
    AsBuilts = 8,
    FireEngineeringRreport = 9,
    AnnualFireStatement = 10,
    FittingsAndFinishes = 11,
    InspectionEvidence = 12,
    DefectEvidence = 13,
    Other = 14
}

export enum DocumentTypeNamesEnum {
    PlanningPermit = 'Planning Permit',
    BuildingPermit = 'Building Permit',
    OccupancyPermit = 'Occupancy Permit',
    EvacuationPlans = 'Evacuation Plans',
    UserManual = 'User Manual',
    FloorPlans = 'Floor Plans',
    Drawings = 'Drawings',
    AsBuilts = 'As-builts',
    FireEngineeringRreport = 'Fire engineering report',
    AnnualFireStatement = 'Annual fire statement',
    FittingsAndFinishes = 'Fittings & Finishes',
    InspectionEvidence = 'Inspection Evidence',
    DefectEvidence = 'Defect Evidence',
    Other = 'Other'
}

export const documentTypesOptions: { id: DocumentTypeEnum, name: DocumentTypeNamesEnum }[] = [
    { id: DocumentTypeEnum.AnnualFireStatement, name: DocumentTypeNamesEnum.AnnualFireStatement },
    { id: DocumentTypeEnum.AsBuilts, name: DocumentTypeNamesEnum.AsBuilts },
    { id: DocumentTypeEnum.BuildingPermit, name: DocumentTypeNamesEnum.BuildingPermit },
    { id: DocumentTypeEnum.DefectEvidence, name: DocumentTypeNamesEnum.DefectEvidence },
    { id: DocumentTypeEnum.Drawings, name: DocumentTypeNamesEnum.Drawings },
    { id: DocumentTypeEnum.EvacuationPlans, name: DocumentTypeNamesEnum.EvacuationPlans },
    { id: DocumentTypeEnum.FireEngineeringRreport, name: DocumentTypeNamesEnum.FireEngineeringRreport },
    { id: DocumentTypeEnum.FittingsAndFinishes, name: DocumentTypeNamesEnum.FittingsAndFinishes },
    { id: DocumentTypeEnum.FloorPlans, name: DocumentTypeNamesEnum.FloorPlans },
    { id: DocumentTypeEnum.InspectionEvidence, name: DocumentTypeNamesEnum.InspectionEvidence },
    { id: DocumentTypeEnum.OccupancyPermit, name: DocumentTypeNamesEnum.OccupancyPermit },
    { id: DocumentTypeEnum.PlanningPermit, name: DocumentTypeNamesEnum.PlanningPermit },
    { id: DocumentTypeEnum.UserManual, name: DocumentTypeNamesEnum.UserManual },
    { id: DocumentTypeEnum.Other, name: DocumentTypeNamesEnum.Other }
];

export enum StarRatings {
    ONESTAR = '1 star',
    TWOSTARs = '2 stars',
    THREESTARS = '3 stars',
    FOURSTARS = '4 stars',
    FIVESTARS = '5 stars',
}

export enum BuildingStatus {
    UNDERCONSTRUCTION = 10,
    HAND0VER = 20,
}

export enum BuildingStatusNames {
    UNDERCONSTRUCTION = 'Under construction',
    HAND0VER = 'Handed over',
}

export enum WatchlistState {
    NONWATCHLIST = 'Non Watchlist',
    WATCHLIST = 'Watchlist',
}

export enum KeyTypes {
    ESMSAFTYMEATURE = 10,
    ESMNATURE = 20,
    ESMBCAPROVISION = 30,
    ESMFREQUENCY = 40,
    DOCUMENTDATE = 50
}

export enum ContactTypes {
    BUILDER = 10,
    BUILDINGMANAGER = 20,
    BUILDINGSURVEYOR = 30,
    DEVELOPER = 40,
    INSURER = 50,
    SUBCONTRACTOR = 60,
    FACILITIESMANAGER = 70
}

export enum ContactType {
    BUILDER = 'Builder',
    BUILDINGMANAGER = 'Building manager',
    BUILDINGSURVEYOR = 'Building surveyor',
    DEVELOPER = 'Developer',
    INSURER = 'Insurer',
    SUBCONTRACTOR = 'Sub-contractor',
    FACILITIESMANAGER = 'Facilities manager'
}

export enum KeyDocumentTypes {
    PLANNING_PERMIT = 1,
    BUILDING_PERMIT = 2,
    OCCUPANCY_PERMIT = 3,
    ASBUILT = 8,
    FIRE_ENGINEERING_REPORT = 9,
    EVACUATION_PLAN = 4,
    ANNUAL_FIRE_STATEMENT = 10
}

export enum KeyDocumentType {
    BUILDING_PERMIT = 'Building permits',
    OCCUPANCY_PERMIT = 'Occupancy permits',
    PLANNING_PERMIT = 'Planning permits',
    ASBUILT = 'As-built',
    FIRE_ENGINEERING_REPORT = 'Fire engineering reports',
    EVACUATION_PLAN = 'Evacuation plans',
    ANNUAL_FIRE_STATEMENT = 'Annual fire statements'
}

// Reverse mapping of KeyDocumentType to KeyDocumentTypes
export const documentTypeToIdMap: { [key in keyof typeof KeyDocumentType]: KeyDocumentTypes } = {
    BUILDING_PERMIT: KeyDocumentTypes.BUILDING_PERMIT,
    OCCUPANCY_PERMIT: KeyDocumentTypes.OCCUPANCY_PERMIT,
    PLANNING_PERMIT: KeyDocumentTypes.PLANNING_PERMIT,
    ASBUILT: KeyDocumentTypes.ASBUILT,
    FIRE_ENGINEERING_REPORT: KeyDocumentTypes.FIRE_ENGINEERING_REPORT,
    EVACUATION_PLAN: KeyDocumentTypes.EVACUATION_PLAN,
    ANNUAL_FIRE_STATEMENT: KeyDocumentTypes.ANNUAL_FIRE_STATEMENT
};

export const keyDocumentTypeOptions: { id: KeyDocumentTypes, name: KeyDocumentType }[] = [
    { id: KeyDocumentTypes.PLANNING_PERMIT, name: KeyDocumentType.PLANNING_PERMIT },
    { id: KeyDocumentTypes.OCCUPANCY_PERMIT, name: KeyDocumentType.OCCUPANCY_PERMIT },
    { id: KeyDocumentTypes.BUILDING_PERMIT, name: KeyDocumentType.BUILDING_PERMIT },
    { id: KeyDocumentTypes.ASBUILT, name: KeyDocumentType.ASBUILT },
    { id: KeyDocumentTypes.FIRE_ENGINEERING_REPORT, name: KeyDocumentType.FIRE_ENGINEERING_REPORT },
    { id: KeyDocumentTypes.EVACUATION_PLAN, name: KeyDocumentType.EVACUATION_PLAN },
    { id: KeyDocumentTypes.ANNUAL_FIRE_STATEMENT, name: KeyDocumentType.ANNUAL_FIRE_STATEMENT },
];

export enum Filters {
    COUNTRIES = 'Countries',
    STATE_OR_TERRITORY = 'State or territory',
    CITY = 'Suburb',
    CLASS = 'Class',
    BUILDINGSTATUS = 'Building status',
    BUILDINGSTATE = 'Building state',
    STARRATING = 'Star rating',
    WATCHLIST = 'Watchlist',
    INTEGRITY = 'Building Integrity'
}
export enum OrganizationTypes {
    ADMIN = 'Admin',
    OWNER = 'Owner',
    BUILDER = 'Builder',
    MAINTENANCE_CONTRACTOR = 'Maintenance Contractor',
}
export enum OrganizationType {
    ADMIN = 5,
    OWNER = 10,
    BUILDER = 30,
    MAINTENANCE_CONTRACTOR = 20
}
export enum CONTACTS {
    GENERAL = 'General', OWNER = 'Owner', OPERATOR = 'Operator', PROPERTY_MANAGER = 'Property manager', FACILITY_MANAGER = 'Facility manager', FIRE_WARDEN = 'Fire warden', BUILDER = 'Builder'
}

export enum TimeInterval {
    Daily = 1,
    Weekly = 2,
    Monthly = 3,
    Yearly = 4
}

// used for the accordian to open up specific panels
export enum ESMRequirementStatusIndex {
    OVERDUE = 0,
    UPCOMING = 1,
    NOTSTARTED = 2,
    COMPLETE = 3,
    MISSED = 4,
}

export enum ESMRequirementStatusNames {
    COMPLETE = 'Completed',
    UPCOMING = 'Upcoming',
    OVERDUE = 'Overdue',
    NOTSTARTED = 'Not Started',
    MISSED = 'Missed',
}

export enum MODE {
    CREATE, EDIT
}

export enum NotificationSection {
    ESMOverdue = 1,
    ESMUpcominng = 2,
    ABSSOverdue = 3,
    ABSSDue = 4,
    OrgAssignedTask = 5,
    UserAssignedTask = 6
}