<ng-container *ngIf="viewType !== 'tags-only'">
    <qpx-file-upload [buildingId]="buildingId"
                     [shouldCheckForDuplicates]="shouldCheckForDuplicates"
                     [multiple]="'multiple'"
                     [withDragAndDrop]="true"
                     [isLoading]="!isLoaded"
                     (processingChange)="fileProcessingChange($event)"
                     (fileListChange)="onFileUploadListChanged($event)">
    </qpx-file-upload>
</ng-container>

<div class="file-list">
    <ng-container *ngIf="viewType === 'upload-with-tags' || viewType === 'tags-only'">
        <qpx-loading-screen *ngIf="!isLoaded"></qpx-loading-screen>
        <div *ngIf="isLoaded && files?.length">
            <qpx-file-list [files]="files"
                           (fileListChange)="onFileListChange()"></qpx-file-list>
        </div>
    </ng-container>

    <ng-container *ngIf="viewType === 'upload-with-file-list'">
        <qpx-loading-screen *ngIf="!isLoaded; else fileList"></qpx-loading-screen>
        <ng-template #fileList>
            <div class="file-item"
                 *ngFor="let file of files">
                <span>{{ file.file.name }}</span>
                <mat-icon (click)="onFileRemove(file.id)">close</mat-icon>
            </div>
        </ng-template>
    </ng-container>
</div>