import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiServiceBase } from '../api-service-base';
import { ICreateUser, IUpdateUser, IUserAccess } from '../models';
import { User } from '../models/user.model';

@Injectable({
    providedIn: 'root'
})
export class UserApiService extends ApiServiceBase {
    private apiUserBase = `${this.appConfig.apiBase}/api/users`;

    userMe$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

    getUsersMe() {
        const url = `${this.apiUserBase}/me`;
        return this.http.get<any>(url).pipe(
            map((response) => {
                this.userMe$.next(response);
                return response;
            }),
            catchError(this.handleError)
        );
    }

    getUser(id: string) {
        const url = `${this.apiUserBase}/${id}`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    addUser(user: ICreateUser) {
        const url = `${this.apiUserBase}`;
        return this.http.post<any>(url, user).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    updateUser(user: IUpdateUser) {
        const url = `${this.apiUserBase}`;
        return this.http.put<any>(url, user).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    updateUserAccess(user: IUpdateUser) {
        const url = `${this.apiUserBase}/access`;
        return this.http.put<any>(url, user).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getAllUsers(postBody: any) {
        const url = `${this.apiUserBase}/all`;
        return this.http.post<any>(url, postBody).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    geAccesstUser(id: string): Observable<IUserAccess> {
        const url = `${this.apiUserBase}/access/${id}`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    deleteUser(id: string) {
        const url = `${this.apiUserBase}/${id}`;
        return this.http.delete<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    inactivateUser(id: string) {
        const url = `${this.apiUserBase}/inactive/${id}`;
        return this.http.delete<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    reactiveateUser(id: string) {
        const url = `${this.apiUserBase}/reactive/${id}`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }
}
