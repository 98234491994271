import { CommonModule } from '@angular/common';
import {
    Component,
    DestroyRef,
    Inject,
    OnInit
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DuplicatesModalService } from './duplicates-modal.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


export interface IDuplicatesDialogData {
    duplicates?: string[];
    title?: string;
    description?: string;
    question?: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
}

@Component({
    selector: 'qpx-duplicates-modal',
    templateUrl: './duplicates-modal.component.html',
    styleUrls: ['./duplicates-modal.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule
    ],
})
export class DuplicatesModalComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<DuplicatesModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDuplicatesDialogData,
        private destroyRef: DestroyRef,
        private duplicatesModalService: DuplicatesModalService
    ) { }

    ngOnInit(): void {
        this.duplicatesModalService.dialogDataChange$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((data: IDuplicatesDialogData | null) => {
                if (data?.title) {
                    this.data.title = data.title;
                }
                if (data?.description) {
                    this.data.description = data.description;
                }
                if (data?.question) {
                    this.data.question = data.question;
                }
                if (data?.cancelButtonText) {
                    this.data.cancelButtonText = data.cancelButtonText;
                }
                if (data?.confirmButtonText) {
                    this.data.confirmButtonText = data.confirmButtonText;
                }
            });
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}
